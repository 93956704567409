(function ($) {
    'use strict';

    $.navigation = new Object({
        init: function () {
            $('ul#main').slicknav({
                allowParentLinks: true,
                prependTo: '.mobile-nav',
                label: '',
            });

            /**
             * add the meta navigation to the main navigation ul
             */
            $('.slicknav_nav').append($('ul#meta li').clone().addClass('meta'));
        },
    });
})(jQuery);
