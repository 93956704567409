// the supseven main javascript file

(function ($) {
    $.breakpoints.init();
    $.navigation.init();
    $.video.init();
    $.lightbox.init();

    /**
     *  faq hide/show answers
     */
    $('.s-faq a.question').on('click', function (e) {
        $('.s-faq div').hide();
        $(this).parent().next('div').show();
        e.preventDefault();
    });

    /**
     * adds elements to sidebar.
     */
    if ($('form[name="newRegistration"] .card').length > 0) {
        $('aside.content-sidebar').prepend($('form[name="newRegistration"] .card'));
    }
})(jQuery);

$(window).on('resize', function () {});

$(window).on('scroll', function () {});
