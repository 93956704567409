(function ($) {
    'use strict';

    $.video = new Object({
        init: function () {
            $('.js-loadVideo').click(function (e) {
                e.preventDefault();

                var $elem = $(this).parent().parent().parent(),
                    $videoId = $elem.data('youtube');

                $elem.prepend(
                    $('<iframe />')
                        .attr('src', 'https://www.youtube-nocookie.com/embed/' + $videoId + '?rel=0&autoplay=1')
                        .attr('width', 640)
                        .attr('height', 385)
                        .attr('frameborder', 0)
                        .attr('allowfullscreen', 'allowfullscreen')
                        .addClass('iframe'),
                );

                $('.embed-responsive', $elem).remove();
                $('.CE_YOUTUBE_GDPR_OVERLAY', $elem).remove();
            });
        },
    });
})(jQuery);
