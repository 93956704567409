(function ($) {
    'use strict';

    $.lightbox = new Object({
        init: function () {
            $('.lightbox').magnificPopup({
                type: 'image',
            });
        },
    });
})(jQuery);
